import { forOwn, isNil } from 'lodash-es'

import { PROJECT_IDENTIFY } from './constant'
import { getByteSize } from './util'

// key 前缀
const LOCAL_KEY_PREFIX = PROJECT_IDENTIFY

const NULL_STR = 'null'

const UNDEFINED_STR = 'undefined'

/**
 * 本地缓存的最大值：5MB
 */
const DANGER_SIZE = 5 * 1024 * 1024 - 100 * 1024

// 简单的 key helper
export const getKey = (key: string) => `${LOCAL_KEY_PREFIX}_${key}`

/**
 * localStorage 简单封装
 */
const store = {
  get<T>(key: string) {
    const value = localStorage.getItem(getKey(key))
    if (value && value !== NULL_STR && value !== UNDEFINED_STR) {
      return JSON.parse(value) as T
    }
    return undefined
  },
  set(key: string, data: unknown) {
    if (isNil(data)) {
      this.remove(key)
    } else {
      localStorage.setItem(getKey(key), JSON.stringify(data))
    }
  },
  remove(...keys: string[]) {
    keys.forEach(key => localStorage.removeItem(getKey(key)))
  },
  getSize() {
    let content = ''

    forOwn(localStorage, value => {
      content += value
    })

    return getByteSize(content)
  },
  isDanger() {
    return this.getSize() >= DANGER_SIZE
  }
}

export default store
