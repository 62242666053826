/**
 * @desc 根据传入业务员id和业务类型查询业务单据
 */

import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type CustomerCollectGetByBusinessParams = Partial<{
  /** businessId */
  businessId?: string
  /** collectType */
  collectType?: 'GOODS_COLLECT' | 'CUSTOMER_COLLECT'
}>

export type CustomerCollectGetByBusinessResponse = Array<object>

export const customerCollectGetByBusinessMethod = 'GET'

export const customerCollectGetByBusinessUrl =
  '/hsu/v1/customer/collect/getByBusiness'

export function customerCollectGetByBusinessRequest(
  params?: CustomerCollectGetByBusinessParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<CustomerCollectGetByBusinessResponse>({
    url: customerCollectGetByBusinessUrl,
    method: customerCollectGetByBusinessMethod,
    params,
    ...options
  })
}

customerCollectGetByBusinessRequest.method = customerCollectGetByBusinessMethod
customerCollectGetByBusinessRequest.url = customerCollectGetByBusinessUrl
