/**
 * @desc 审批记录
 */
import { ApproveRecordEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type WorkFlowListRecordParams = Partial<{
  /** workFlowId */
  workFlowId: string
}>

export type WorkFlowListRecordResponse = Array<ApproveRecordEntity>

export const workFlowListRecordMethod = 'GET'

export const workFlowListRecordUrl = '/hsu/v1/workflow/record/{workFlowId}'

export function workFlowListRecordRequest(
  params?: WorkFlowListRecordParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<WorkFlowListRecordResponse>({
    url: workFlowListRecordUrl,
    method: workFlowListRecordMethod,
    params,
    ...options
  })
}

workFlowListRecordRequest.method = workFlowListRecordMethod
workFlowListRecordRequest.url = workFlowListRecordUrl
