/**
 * @desc 登录后获取用户信息
 */
import { LoginInfo } from 'api/auth/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type UserLoginInfoParams = Partial<{}>

export type UserLoginInfoResponse = LoginInfo

export const userLoginInfoMethod = 'GET'

export const userLoginInfoUrl = '/auth/v1/user/loginInfo'

export function userLoginInfoRequest(
  params?: UserLoginInfoParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<UserLoginInfoResponse>({
    url: userLoginInfoUrl,
    method: userLoginInfoMethod,
    params,
    ...options
  })
}

userLoginInfoRequest.method = userLoginInfoMethod
userLoginInfoRequest.url = userLoginInfoUrl
