import { Suspense, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PageLoading from 'common/layouts/components/PageLoading'
import { NODE_ENV } from 'common/lib/constant'
import { flattenArrayTree } from 'common/lib/util'
import LayoutSwitch from 'common/router/components/LayoutSwitch'

import GlobalRouterProvider from './components/Provider/GlobalRouterProvider'
import PageRouterProvider from './components/Provider/PageRouterProvider'
import RouterView from './components/RouterView'
import { RouterProps } from './interface'
import { withProcess } from './util'

const NotFoundRedirect =
  NODE_ENV === 'development'
    ? () => null
    : () => <Redirect exact to='/exception/404' />

const Router = (props: RouterProps) => {
  const { routes } = props

  const processedRoutes = useMemo(() => withProcess(routes), [routes])

  const flattenRoutes = useMemo(
    () => flattenArrayTree(processedRoutes),
    [processedRoutes]
  )

  return (
    <GlobalRouterProvider routes={processedRoutes}>
      <PageRouterProvider>
        <LayoutSwitch>
          <Suspense fallback={<PageLoading />}>
            <Switch>
              {flattenRoutes.map(route => {
                return (
                  <Route
                    key={route.key}
                    exact
                    path={route.path}
                    render={() => {
                      return <RouterView route={route} />
                    }}
                    sensitive={route.sensitive}
                    strict={route.strict}
                  />
                )
              })}
              <NotFoundRedirect />
            </Switch>
          </Suspense>
        </LayoutSwitch>
      </PageRouterProvider>
    </GlobalRouterProvider>
  )
}

export default Router
