import { Col, Row } from 'antd'

import UserAvator from 'common/components/UserAvator'
import { useCurrentUser } from 'common/context/CurrentUser'
import { UserConfigProvider } from 'common/context/UserConfig'

import CustomEntry from './widgets/CustomEntry/index'
import Todo from './widgets/Todo/index'
import { getGreeting } from './widgets/util'

import './index.less'

const HomePage = () => {
  const { deptName, userName = '欢迎' } = useCurrentUser()

  return (
    <UserConfigProvider>
      <div className='home'>
        <div className='home-header'>
          <div className='home-avator'>
            <UserAvator text={userName[0]} />
          </div>
          <div className='home-profile'>
            <h1 className='home-greet'>{getGreeting() + '，' + userName}</h1>
            <div className='home-desc'>{deptName}</div>
          </div>
        </div>
        <div className='home-content'>
          <Row gutter={24}>
            <Col span={12}>
              <Todo />
            </Col>
            <Col span={12}>
              <CustomEntry />
            </Col>
          </Row>
        </div>
      </div>
    </UserConfigProvider>
  )
}

export default HomePage
