/**
 * @desc 根据id查询
 */
import { PaymentRequestFormEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type PaymentRequestFormGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type PaymentRequestFormGetByIdResponse = PaymentRequestFormEntity

export const paymentRequestFormGetByIdMethod = 'GET'

export const paymentRequestFormGetByIdUrl =
  '/hsu/v1/paymentRequestForm/byId/{id}'

export function paymentRequestFormGetByIdRequest(
  params?: PaymentRequestFormGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<PaymentRequestFormGetByIdResponse>({
    url: paymentRequestFormGetByIdUrl,
    method: paymentRequestFormGetByIdMethod,
    params,
    ...options
  })
}

paymentRequestFormGetByIdRequest.method = paymentRequestFormGetByIdMethod
paymentRequestFormGetByIdRequest.url = paymentRequestFormGetByIdUrl
