import { ShoppingCartOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  UPDATE_OPTION,
  DELETE_OPTION,
  READ_OPTION,
  AUDIT_OPTION
} from 'common/router/option'

import {
  BARGAIN_OFFER_OPTION,
  BARGAIN_OFFER_AGAIN_OPTION
} from 'hsu/pages/Purchase/Bargain/option'
import {
  COMPARE_AUDIT_BTN,
  COMPARE_AUDIT_EDIT_BTN
} from 'hsu/pages/Purchase/Compare/option'
import { ENQUIRY_VIEW_COMPARE_OPTION } from 'hsu/pages/Purchase/Enquiry/option'
import { OFFER_OPTION } from 'hsu/pages/Purchase/Offer/option'

const purchase: RouteItem = {
  key: 'purchase',
  name: '采购管理',
  path: '/purchase',
  redirect: '/purchase/plan',
  layout: 'PageLayout',
  icon: <ShoppingCartOutlined />,
  children: [
    {
      key: 'plan',
      name: '计划管理',
      path: '/purchase/plan',
      redirect: '/purchase/plan/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'planList',
          name: '计划列表',
          path: '/purchase/plan/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            AUDIT_OPTION
          ],
          component: () => import('hsu/pages/Purchase/Plan/List')
        },
        {
          key: 'planCreate',
          name: '新增计划',
          path: '/purchase/plan/create',
          component: () => import('hsu/pages/Purchase/Plan/Create')
        },
        {
          key: 'planEdit',
          name: '编辑计划',
          path: '/purchase/plan/edit',
          component: () => import('hsu/pages/Purchase/Plan/Edit')
        },
        {
          key: 'planDetail',
          name: '计划详情',
          path: '/purchase/plan/detail',
          component: () => import('hsu/pages/Purchase/Plan/Detail')
        }
      ]
    },
    {
      key: 'pmBargain',
      name: '议价管理',
      path: '/purchase/bargain',
      redirect: '/purchase/bargain/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'pmBargainList',
          name: '议价单列表',
          path: '/purchase/bargain/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            BARGAIN_OFFER_OPTION,
            BARGAIN_OFFER_AGAIN_OPTION
          ],
          component: () => import('hsu/pages/Purchase/Bargain/List')
        },
        {
          key: 'pmBargainCreate',
          name: '新增议价单',
          path: '/purchase/bargain/create',
          component: () => import('hsu/pages/Purchase/Bargain/Create')
        },
        {
          key: 'pmBargainEdit',
          name: '编辑议价单',
          path: '/purchase/bargain/edit',
          component: () => import('hsu/pages/Purchase/Bargain/Edit')
        },
        {
          key: 'pmBargainDetail',
          name: '议价单详情',
          path: '/purchase/bargain/detail',
          component: () => import('hsu/pages/Purchase/Bargain/Detail')
        },
        {
          key: 'pmBargainOffer',
          name: '议价单报价',
          path: '/purchase/bargain/offer',
          component: () => import('hsu/pages/Purchase/Bargain/Offer')
        },
        {
          key: 'pmBargainOfferAgain',
          name: '议价单确认报价',
          path: '/purchase/bargain/offerAgain',
          component: () => import('hsu/pages/Purchase/Bargain/OfferAgain')
        }
      ]
    },
    /**
     * 其实是「询价单」模块
     */
    {
      key: 'pmEnquiry',
      name: '比价管理',
      path: '/purchase/enquiry',
      redirect: '/purchase/enquiry/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'pmEnquiryList',
          name: '比价单列表',
          path: '/purchase/enquiry/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            ENQUIRY_VIEW_COMPARE_OPTION
          ],
          component: () => import('hsu/pages/Purchase/Enquiry/List')
        },
        {
          key: 'pmEnquiryCreate',
          name: '新增比价单',
          path: '/purchase/enquiry/create',
          component: () => import('hsu/pages/Purchase/Enquiry/Create')
        },
        {
          key: 'pmEnquiryEdit',
          name: '编辑比价单',
          path: '/purchase/enquiry/edit',
          component: () => import('hsu/pages/Purchase/Enquiry/Edit')
        },
        {
          key: 'pmEnquiryDetail',
          name: '比价单详情',
          path: '/purchase/enquiry/detail',
          component: () => import('hsu/pages/Purchase/Enquiry/Detail')
        }
      ]
    },
    {
      key: 'pmOffer',
      name: '报价单管理',
      path: '/purchase/offer',
      redirect: '/purchase/offer/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'pmOfferList',
          name: '报价单列表',
          path: '/purchase/offer/list',
          options: [AUDIT_OPTION, OFFER_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Purchase/Offer/List')
        },
        {
          key: 'pmOfferEdit',
          name: '报价页面',
          path: '/purchase/offer/edit',
          component: () => import('hsu/pages/Purchase/Offer/Edit')
        },
        {
          key: 'pmOfferDetail',
          name: '报价单详情',
          path: '/purchase/offer/detail',
          component: () => import('hsu/pages/Purchase/Offer/Detail')
        }
      ]
    },
    {
      key: 'pmCompare',
      name: '比价单管理',
      path: '/purchase/compare',
      redirect: '/purchase/enquiry/list',
      hideInMenu: true,
      hideChildrenInMenu: true,
      children: [
        {
          key: 'pmCompareList',
          name: '比价单列表',
          path: '/purchase/compare/list',
          options: [COMPARE_AUDIT_BTN],
          component: () => import('hsu/pages/Purchase/Compare/List')
        },
        {
          key: 'pmCompareEdit',
          name: '比价',
          path: '/purchase/compare/edit',
          options: [COMPARE_AUDIT_EDIT_BTN],
          component: () => import('hsu/pages/Purchase/Compare/Edit')
        },
        {
          key: 'pmCompareDetail',
          name: '比价单详情',
          path: '/purchase/compare/detail',
          component: () => import('hsu/pages/Purchase/Compare/Detail')
        }
      ]
    },
    {
      key: 'pmOrder',
      name: '订货函管理',
      path: '/purchase/order',
      redirect: '/purchase/order/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'pmOrderList',
          name: '订货函列表',
          path: '/purchase/order/list',
          options: [CREATE_OPTION, AUDIT_OPTION],
          component: () => import('hsu/pages/Purchase/Order/List')
        },
        {
          key: 'pmOrderCreate',
          name: '新增订货函',
          path: '/purchase/order/create',
          component: () => import('hsu/pages/Purchase/Order/Create')
        },
        {
          key: 'pmOrderEdit',
          name: '编辑订货函',
          path: '/purchase/order/edit',
          component: () => import('hsu/pages/Purchase/Order/Edit')
        },
        {
          key: 'pmOrderDetail',
          name: '订货函详情',
          path: '/purchase/order/detail',
          component: () => import('hsu/pages/Purchase/Order/Detail')
        }
      ]
    },
    {
      key: 'purchaseSendGoodsBill',
      name: '送货通知单',
      path: '/purchase/sendGoodsBill',
      redirect: '/purchase/sendGoodsBill/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'purchaseSendGoodsBillList',
          name: '送货通知单列表',
          path: '/purchase/sendGoodsBill/list',
          component: () => import('hsu/pages/Purchase/SendGoodsBill/List')
        },
        {
          key: 'purchaseSendGoodsBillDetail',
          name: '送货通知单详情',
          path: '/purchase/sendGoodsBill/detail',
          component: () => import('hsu/pages/Purchase/SendGoodsBill/Detail')
        }
      ]
    },
    {
      key: 'pmEnquiryApprove',
      name: '比价审批管理',
      path: '/purchase/enquiryApprove',
      redirect: '/purchase/enquiryApprove/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'pmEnquiryApproveList',
          name: '比价单审批列表',
          path: '/purchase/enquiryApprove/list',
          options: [CREATE_OPTION, UPDATE_OPTION, DELETE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Purchase/EnquiryApprove/List')
        },
        {
          key: 'pmEnquiryApproveCreate',
          name: '新增比价单审批',
          path: '/purchase/enquiryApprove/create',
          component: () => import('hsu/pages/Purchase/EnquiryApprove/Create')
        },
        {
          key: 'pmEnquiryApproveEdit',
          name: '编辑比价单审批',
          path: '/purchase/enquiryApprove/edit',
          component: () => import('hsu/pages/Purchase/EnquiryApprove/Edit')
        },
        {
          key: 'pmEnquiryApproveDetail',
          name: '比价单审批详情',
          path: '/purchase/enquiryApprove/detail',
          component: () => import('hsu/pages/Purchase/EnquiryApprove/Detail')
        }
      ]
    },
    {
      key: 'bargainApprove',
      name: '议价审批管理',
      path: '/purchase/bargainApprove',
      redirect: '/purchase/bargainApprove/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'bargainApproveList',
          name: '议价审批单列表',
          path: '/purchase/bargainApprove/list',
          options: [CREATE_OPTION, UPDATE_OPTION, DELETE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Purchase/BargainApprove/List')
        },
        {
          key: 'bargainApproveCreate',
          name: '新增议价审批单',
          path: '/purchase/bargainApprove/create',
          component: () => import('hsu/pages/Purchase/BargainApprove/Create')
        },
        {
          key: 'bargainApproveEdit',
          name: '编辑议价审批单',
          path: '/purchase/bargainApprove/edit',
          component: () => import('hsu/pages/Purchase/BargainApprove/Edit')
        },
        {
          key: 'bargainApproveDetail',
          name: '议价审批单详情',
          path: '/purchase/bargainApprove/detail',
          component: () => import('hsu/pages/Purchase/BargainApprove/Detail')
        }
      ]
    }
  ]
}

export default purchase
