import { BorderOutlined } from '@ant-design/icons'
import { isUndefined, last } from 'lodash-es'

import { parseVariableUrl } from '../lib/util'

import { RouteInjectedData, RouteItem } from './interface'

/**
 * 判断当前路由是否是目录
 *
 * @param children 子路由
 */
const isDirectoryRoute = (value: RouteItem) => {
  return (
    Array.isArray(value.children) &&
    value.children.some(item => item.name && !item.hideInMenu)
  )
}

/**
 * 处理路由配置：
 * 1. key
 * 2. parentKeys
 * 3. component
 */
export const withProcess = (() => {
  const parents: RouteItem[] = []

  return (data: RouteItem[]) => {
    return data.map(item => {
      const {
        exact,
        sensitive,
        authorize: authroize,
        layout,
        hideChildrenInMenu,
        hideInMenu
      } = item
      const isDirectory = isDirectoryRoute(item)
      const parent = last(parents)
      item.parent = parent
      // item.lazy = props ? false : lazy !== false
      item.authorize = isUndefined(authroize)
        ? parent?.authorize ?? true
        : authroize
      item.sensitive = sensitive !== true
      item.parentKeys = parents.map(item => item.key)
      item.exact = isUndefined(exact) ? !isDirectory : exact
      item.layout = layout || (parent?.layout ?? 'BasicLayout')
      item.hideInMenu =
        parent?.hideInMenu ?? parent?.hideChildrenInMenu ?? hideInMenu
      item.hideChildrenInMenu =
        parent?.hideInMenu ?? parent?.hideChildrenInMenu ?? hideChildrenInMenu
      parents.push(item)
      if (item.children) {
        item.children = withProcess(item.children)
      }
      parents.pop()
      return item
    })
  }
})()

/**
 * 判断当前路由是否允许访问
 *
 * @param route 路由信息
 * @param permissions 分配的权限信息
 * @param roles 用户的角色集合
 * @returns boolean
 */
export const isAuthorized = (
  route?: RouteItem,
  permissions?: string[],
  isAdmin?: boolean
) => {
  if (isAdmin) {
    return true
  }

  if (!route) {
    return false
  }

  if (route.authorize === false) {
    return true
  }

  if (!permissions?.length) {
    return false
  }

  return permissions.includes(route.key)
}

/**
 * 判断页面是否需要走权限控制
 *
 * @param menu 菜单项
 * @returns boolean
 */
export const shouldAuthorize = (menu: RouteItem) => menu.authorize !== false

/**
 * 解析页面下的按钮都应的 key
 *
 * @param pageKey 页面路由的 key
 * @param optionIdentify 操作按钮的标识
 * @returns 操作按钮在菜单树中的 key
 */
export const resolveOptionKey = (pageKey: string, optionIdentify: string) => {
  return pageKey + '#' + optionIdentify
}

/**
 * 因为 MenuTree 组件获取到的可能只有子节点的 key 集合（半选状态下的节点不在 checked 属性中），
 * 所以需要根据路由来处理一下权限，把父节点手动补充进去。
 *
 * @param routes 路由信息
 * @param permissions 权限信息
 */
export const cookWholePermissions = (
  routes: RouteItem[],
  permissions?: string[]
) => {
  if (!(permissions && permissions.length)) {
    return []
  }

  const keys = new Set(permissions)
  const cook = (routes: RouteItem[]) => {
    for (const route of routes) {
      if (route.children) {
        cook(route.children)
      }
      if (keys.has(route.key) && route.parent) {
        keys.add(route.parent.key)
      }
    }
  }

  cook(routes)

  return [...keys]
}

/**
 * 过滤路由信息
 *
 * @param routes 路由信息
 * @param permissions 分配的权限信息
 * @param roles 用户的角色集合
 * @returns RouteItem[]
 */
export const cookAuthorizedRoutes = (
  routes: RouteItem[],
  permissions: string[],
  isAdmin: boolean
): RouteItem[] => {
  return routes
    .filter(item => isAuthorized(item, permissions, isAdmin))
    .map(item => ({
      ...item,
      children: item.children
        ? cookAuthorizedRoutes(item.children, permissions, isAdmin)
        : undefined
    }))
}

/**
 * 把 options 配置转换成 children 配置
 *
 * @param route 路由配置
 * @returns
 */
export const options2Children = (route: RouteItem) => {
  const { key, options } = route

  const result: RouteItem[] = []

  if (options) {
    for (const option of options) {
      result.push({
        ...option,
        parent: route,
        key: resolveOptionKey(key, option.key),
        icon: <BorderOutlined />
      })
    }
  }

  return result
}

/**
 * 解析 route item 配置
 *
 * 1. 把 options 追加到 children 中
 */
export const resolveRouteItem = (item: RouteItem) => {
  const result = { ...item }
  const newChildren = options2Children(result)
  result.children = result.children
    ? [...result.children.map(resolveRouteItem), ...newChildren]
    : newChildren

  return result
}

/**
 * 把 route 配置中的 options 属性解析成 children
 *
 * @param routes 路由信息
 * @returns 菜单信息
 */
export const cookWholeMenus = (routes: RouteItem[]): RouteItem[] => {
  return routes.map(item => resolveRouteItem(item))
}

/**
 * 获取作为授权菜单树的数据
 *
 * @param routes 路由配置
 * @returns 菜单配置
 */
export const cookAssignableMenus = (routes: RouteItem[]): RouteItem[] => {
  const filter = (routes: RouteItem[]): RouteItem[] => {
    return routes.filter(shouldAuthorize).map(item => ({
      ...item,
      children: item.children ? filter(item.children) : undefined
    }))
  }
  return filter(routes).map(item => resolveRouteItem(item))
}

export const cookAuthorizedMenus = (
  authorizedRoutes: RouteItem[],
  injectedData: RouteInjectedData
): RouteItem[] => {
  return authorizedRoutes.map(item => ({
    ...item,
    path: item.path ? parseVariableUrl(item.path, injectedData) : undefined,
    children: item.children
      ? cookAuthorizedMenus(item.children, injectedData)
      : undefined
  }))
}
