import { useMemo } from 'react'

import { useCurrentUser } from 'common/context/CurrentUser'
import { GlobalRouterContext } from 'common/router/context'
import { GlobalRouterProviderProps } from 'common/router/interface'
import {
  cookAssignableMenus,
  cookAuthorizedMenus,
  cookAuthorizedRoutes,
  cookWholeMenus,
  cookWholePermissions
} from 'common/router/util'

const GlobalRouterProvider = (props: GlobalRouterProviderProps) => {
  const { routes, children } = props

  const userInfo = useCurrentUser()

  const { permissions, isAdmin } = userInfo

  const menus = useMemo(() => cookWholeMenus(routes), [routes])

  const wholePermissions = useMemo(
    () => cookWholePermissions(menus, permissions),
    [menus, permissions]
  )

  const authorizedRoutes = useMemo(
    () => cookAuthorizedRoutes(routes, wholePermissions, isAdmin),
    [routes, wholePermissions, isAdmin]
  )

  const authorizedMenus = useMemo(
    () => cookAuthorizedMenus(authorizedRoutes, userInfo),
    [authorizedRoutes, userInfo]
  )

  const assignableMenus = useMemo(
    () => cookAssignableMenus(authorizedRoutes),
    [authorizedRoutes]
  )

  return (
    <GlobalRouterContext.Provider
      children={children}
      value={{
        routes,
        menus,
        authorizedRoutes,
        authorizedMenus,
        assignableMenus,
        wholePermissions
      }}
    />
  )
}

export default GlobalRouterProvider
