import { UngroupOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  DELETE_OPTION,
  READ_OPTION,
  UPDATE_OPTION,
  AUDIT_OPTION,
  INVALID_OPTION
} from 'common/router/option'

const sale: RouteItem = {
  key: 'sale',
  name: '销售管理',
  path: '/sale',
  redirect: '/sale/freezeMoneyBill',
  layout: 'PageLayout',
  icon: <UngroupOutlined />,
  children: [
    {
      key: 'saleFreezeMoneyBill',
      name: '资金冻结单',
      path: '/sale/freezeMoneyBill',
      redirect: '/sale/freezeMoneyBill/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'saleFreezeMoneyBillList',
          name: '资金冻结单列表',
          path: '/sale/freezeMoneyBill/list',
          component: () => import('hsu/pages/Sale/FreezeMoneyBill/List')
        },
        {
          key: 'saleFreezeMoneyBillDetail',
          name: '资金冻结单详情',
          path: '/sale/freezeMoneyBill/detail',
          component: () => import('hsu/pages/Sale/FreezeMoneyBill/Detail')
        }
      ]
    },
    {
      key: 'salePickUpGoods',
      name: '提货单',
      path: '/sale/pickUpGoods',
      redirect: '/sale/pickUpGoods/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'salePickUpGoodsList',
          name: '提货单列表',
          path: '/sale/pickUpGoods/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            AUDIT_OPTION,
            INVALID_OPTION
          ],
          component: () => import('hsu/pages/Sale/PickUpGoods/List')
        },
        {
          key: 'salePickUpGoodsCreate',
          name: '新增提货单',
          path: '/sale/pickUpGoods/create',
          component: () => import('hsu/pages/Sale/PickUpGoods/Create')
        },
        {
          key: 'salePickUpGoodsEdit',
          name: '编辑提货单',
          path: '/sale/pickUpGoods/edit',
          component: () => import('hsu/pages/Sale/PickUpGoods/Edit')
        },
        {
          key: 'salePickUpGoodsDetail',
          name: '提货单详情',
          path: '/sale/pickUpGoods/detail',
          component: () => import('hsu/pages/Sale/PickUpGoods/Detail')
        }
      ]
    }
  ]
}

export default sale
