import { ShopOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  AUDIT_OPTION,
  CREATE_OPTION,
  DELETE_OPTION,
  READ_OPTION,
  UPDATE_OPTION
} from 'common/router/option'

import {
  GOODS_TYPE_CREATE_OPTION,
  GOODS_TYPE_DELETE_OPTION,
  GOODS_TYPE_UPDATE_OPTION
} from 'hsu/pages/Basic/Goods/Type/lib/option'

const baisc: RouteItem = {
  key: 'basic',
  name: '基础数据',
  path: '/basic',
  redirect: '/basic/goods',
  layout: 'PageLayout',
  icon: <ShopOutlined />,
  children: [
    {
      key: 'goods',
      name: '物资档案',
      path: '/basic/goods',
      redirect: '/basic/goods/profile/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'goodsList',
          name: '物资列表',
          path: '/basic/goods/profile/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            AUDIT_OPTION,
            GOODS_TYPE_CREATE_OPTION,
            GOODS_TYPE_DELETE_OPTION,
            GOODS_TYPE_UPDATE_OPTION
          ],
          component: () => import('hsu/pages/Basic/Goods/Combine/index')
        },
        {
          key: 'goodsCreate',
          name: '新增物资',
          path: '/basic/goods/profile/create',
          component: () => import('hsu/pages/Basic/Goods/Profile/Create')
        },
        {
          key: 'goodsEdit',
          name: '编辑物资',
          path: '/basic/goods/profile/edit',
          component: () => import('hsu/pages/Basic/Goods/Profile/Edit')
        },
        {
          key: 'goodsDetail',
          name: '物资详情',
          path: '/basic/goods/profile/detail',
          component: () => import('hsu/pages/Basic/Goods/Profile/Detail')
        }
      ]
    },
    {
      key: 'cargo',
      name: '仓库管理',
      path: '/basic/cargo',
      redirect: '/basic/cargo/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'cargoList',
          name: '仓库列表',
          path: '/basic/cargo/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Basic/Cargo/List')
        },
        {
          key: 'cargoCreate',
          name: '新增仓库',
          path: '/basic/cargo/create',
          component: () => import('hsu/pages/Basic/Cargo/Create')
        },
        {
          key: 'cargoEdit',
          name: '编辑仓库',
          path: '/basic/cargo/edit',
          component: () => import('hsu/pages/Basic/Cargo/Edit')
        },
        {
          key: 'cargoDetail',
          name: '仓库详情',
          path: '/basic/cargo/detail',
          component: () => import('hsu/pages/Basic/Cargo/Detail')
        },
        {
          key: 'cargoGoods',
          name: '仓库物资',
          path: '/basic/cargo/goodsdetail',
          component: () => import('hsu/pages/Basic/Cargo/components/GoodsList')
        }
      ]
    }
  ]
}

export default baisc
