/**
 * @desc 查询当前用户的配置信息
 */
import { UserConfigEntity } from 'api/auth/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type UserConfigQueryCurrentUserConfigParams = Partial<{}>

export type UserConfigQueryCurrentUserConfigResponse = UserConfigEntity

export const userConfigQueryCurrentUserConfigMethod = 'GET'

export const userConfigQueryCurrentUserConfigUrl = '/auth/v1/userConfig'

export function userConfigQueryCurrentUserConfigRequest(
  params?: UserConfigQueryCurrentUserConfigParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<UserConfigQueryCurrentUserConfigResponse>({
    url: userConfigQueryCurrentUserConfigUrl,
    method: userConfigQueryCurrentUserConfigMethod,
    params,
    ...options
  })
}

userConfigQueryCurrentUserConfigRequest.method =
  userConfigQueryCurrentUserConfigMethod
userConfigQueryCurrentUserConfigRequest.url =
  userConfigQueryCurrentUserConfigUrl
