import useSafeState from 'common/hooks/useSafeState'
import { PageRouterContext } from 'common/router/context'
import {
  PageRouterContextValue,
  PageRouterProviderProps
} from 'common/router/interface'

const PageRouterProvider = (props: PageRouterProviderProps) => {
  const { children } = props

  const [state, setState] = useSafeState<PageRouterContextValue>({})

  return (
    <PageRouterContext.Provider
      children={children}
      value={{ ...state, updatePageRouter: setState }}
    />
  )
}

export default PageRouterProvider
