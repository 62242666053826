import { useMemo } from 'react'

import { createSchemaField } from '@formily/react'

import Form from 'common/@formily/components/Form'
import { FormGrid } from 'common/@formily/components/FormGrid'
import { PlaceholderProvider } from 'common/@formily/hooks/placeholder'
import { formGridConfig } from 'common/config/formGrid'
import { useCurrentUser } from 'common/context/CurrentUser'

import useTransformSchema from './hooks/useTransformSchema'
import { CrudSchemaFormProps } from './interface'
import * as tools from './lib/tool'

const CrudSchemaForm = <T extends Record<string, any>>(
  props: CrudSchemaFormProps<T>
) => {
  const {
    /**
     * PreviewText
     */
    placeholder = '-',

    /**
     * FormGrid
     */
    grid,

    /**
     * createSchemaField
     */
    scope,

    /**
     * SchemaField
     */
    items,

    /**
     * CrudSchemaForm
     */
    bodyClassName,
    footer,
    children,

    /**
     * Form
     */
    form,
    ...formProps
  } = props

  const currentUser = useCurrentUser()

  const SchemaField = useMemo(() => {
    return createSchemaField({
      scope: {
        ...scope,
        ...tools,
        $form: form,
        $user: currentUser
      }
    })
  }, [currentUser])

  const schema = useTransformSchema({ items, grid: !!grid })

  const content = (
    <>
      <SchemaField schema={schema} />
      {children}
    </>
  )

  const gridProps =
    typeof grid === 'boolean'
      ? formGridConfig
      : {
          ...formGridConfig,
          ...grid
        }

  return (
    <PlaceholderProvider value={placeholder}>
      <Form form={form} {...formProps}>
        <div className={bodyClassName}>
          {grid ? <FormGrid {...gridProps}>{content}</FormGrid> : content}
        </div>
        {footer}
      </Form>
    </PlaceholderProvider>
  )
}

export default CrudSchemaForm
