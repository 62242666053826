import basic from './basic'
import contract from './contract'
import customer from './customer'
import exception from './exception'
import finance from './finance'
import home from './home'
import inventory from './inventory'
import login from './login'
import purchase from './purchase'
import report from './report'
import sale from './sale'
import system from './system'
import user from './user'

const routes = [
  home,
  login,
  user,
  system,
  exception,
  basic,
  customer,
  purchase,
  sale,
  inventory,
  contract,
  finance,
  report
]

export default routes
