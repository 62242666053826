/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const INVOICE_APPLY_CONFIRM_BTN = 'invoiceApplyConfirmBtn'

export const INVOICE_APPLY_RED_BTN = 'invoiceApplyRedBtn'

export const INVOICE_APPLY_CONFIRM_OPTION: OptionItem = {
  key: INVOICE_APPLY_CONFIRM_BTN,
  name: '开票'
}

export const INVOICE_APPLY_RED_OPTION: OptionItem = {
  key: INVOICE_APPLY_RED_BTN,
  name: '红冲'
}
