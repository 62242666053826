import { FormProvider } from '@formily/react'

import { useCreateForm } from '../../hooks/form'
import FormLayout from '../FormLayout'

import AutoCompleteForm from './AutoCompleteForm'
import { FormProps } from './interface'

export const Form = <T extends Record<string, any>>(props: FormProps<T>) => {
  const { className, style, elRef, name, form, onSubmit, ...restProps } = props

  const finalForm = useCreateForm({
    form
  })

  return (
    <AutoCompleteForm
      ref={elRef}
      className={className}
      name={name}
      style={style}
      onSubmit={() => onSubmit?.(finalForm)}
    >
      <FormProvider form={finalForm}>
        <FormLayout {...restProps} />
      </FormProvider>
    </AutoCompleteForm>
  )
}

export default Form
