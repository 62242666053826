import { useEffect, useMemo } from 'react'

import { noop } from 'lodash-es'

import { AxiosRequest } from 'common/interface/crud'
import { wrapApi } from 'common/lib/util'

import useBoolean from '../useBoolean'
import usePersistFn from '../usePersistFn'
import useSafeState from '../useSafeState'

import Fetcher from './fetcher'
import { Runner, UseRequestOptions, UseRequestResult } from './interface'
import useParamsTracer from './useParamsTracer'

const useRequest = <T, P>(
  options: UseRequestOptions<T, P>
): UseRequestResult<T, P> => {
  const {
    api,
    already,
    autoCancel,
    silent,
    cacheKey,
    cacheTime,
    defaultCache,
    manual,
    defaultParams,
    requestParams,
    onError,
    onSuccess
  } = options || {}

  const wrappedAPi = useMemo(() => {
    if (api) {
      return wrapApi(api, { silent, cancelable: autoCancel })
    }
    return noop as AxiosRequest<T, P>
  }, [api])

  const persistenceApi = usePersistFn(wrappedAPi)

  const fetcher = useMemo(
    () =>
      new Fetcher({
        api: persistenceApi,
        cacheKey,
        cacheTime,
        defaultCache,
        onError,
        onSuccess,
        subscribe(state) {
          setState(state)
        }
      }),
    []
  )

  const [{ run, ...restFetcherState }, setState] = useSafeState(
    fetcher.getState()
  )

  const requestFlag = useParamsTracer(requestParams)

  const request: Runner<T, P> = usePersistFn((params, config) => {
    return run(
      {
        ...defaultParams,
        ...requestParams,
        ...params
      },
      config
    )
  })

  /**
   * 请求时机：
   *   1. 还没进行第一次请求
   *   2. 查询参数变动
   *
   */
  const [shouldRequest, shouldRequestActions] = useBoolean(true)

  useEffect(() => {
    if (shouldRequest === false) {
      shouldRequestActions.toggle(!!requestFlag)
    }
  }, [requestFlag])

  useEffect(() => {
    if (manual === true) {
      return
    }

    if (already === false) {
      return
    }

    if (shouldRequest) {
      shouldRequestActions.setFalse()
      request()
    }
  }, [manual, already, shouldRequest])

  return {
    ...restFetcherState,
    requestFlag,
    run: request
  }
}

export default useRequest
