import { useEffect } from 'react'

import { Noop } from 'common/interface/base'

import usePersistFn from './usePersistFn'

const useUnmount = <T extends Noop>(fn: T) => {
  const fnPersist = usePersistFn(fn)

  useEffect(
    () => () => {
      fnPersist()
    },
    [fnPersist]
  )
}

export default useUnmount
