import { Layout } from 'antd'
import classNames from 'classnames'

import { useSize } from 'common/hooks/useSize'

import LayoutHeaderLeftContent from './LeftContent'
import LayoutHeaderRightContent from './RightContent'

import './index.less'

const { Header } = Layout

const LayoutHeader = () => {
  const size = useSize()

  const prefixCls = 'layout-header'
  const rootClassName = classNames(prefixCls, {
    [`${prefixCls}-sm`]: size === 'small'
  })

  return (
    <Header className={rootClassName}>
      <LayoutHeaderLeftContent />
      <LayoutHeaderRightContent />
    </Header>
  )
}

export default LayoutHeader
