import { useForm } from '@formily/react'
import classNames from 'classnames'

const useClazz = (className?: string) => {
  const { readPretty } = useForm()

  return classNames(className, {
    'is-detail': readPretty
  })
}

export default useClazz
