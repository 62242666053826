import { ComponentType, lazy, memo, useEffect, useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import { PROJECT_TITLE } from 'common/lib/constant'
import usePageRoute from 'common/router/hooks/usePageRouter'

import { DynamicImportType, RouteItem } from '../../interface'
import AuthorizationController from '../AuthorizationController'

type RouterViewProps = {
  route: RouteItem
}

const resolveComponent = ({ component, lazy: lazyLoad }: RouteItem) => {
  if (component) {
    if (lazyLoad !== false) {
      return lazy(component as DynamicImportType)
    }
    return component as ComponentType
  }
  return undefined
}

const RouterView = (props: RouterViewProps) => {
  const {
    route,
    route: { path, redirect, props: extraProps }
  } = props

  const { updatePageRouter } = usePageRoute()

  const RouteComp = useMemo(() => resolveComponent(route), [route])

  useEffect(() => {
    updatePageRouter({ route })
  }, [route])

  useEffect(() => {
    document.title = `${route.name} - ${PROJECT_TITLE}`
  }, [route])

  return (
    <AuthorizationController route={route}>
      {RouteComp && <RouteComp {...extraProps} />}
      {redirect ? <Redirect key={path} from={path} to={redirect} /> : undefined}
    </AuthorizationController>
  )
}

export default memo(RouterView)
