import { forwardRef } from 'react'

import classNames from 'classnames'

import { getCssValue } from 'common/lib/util'

import { ScrollContainerProps } from './interface'

import './index.less'

const ScrollContainer = forwardRef<HTMLDivElement, ScrollContainerProps>(
  (props, ref) => {
    const { className, style, height, children } = props

    const mergedStyle = { ...style, height: getCssValue(height) }

    return (
      <div
        ref={ref}
        className={classNames('scroll-container', className)}
        style={mergedStyle}
      >
        <div className='scroll-container__inner'>{children}</div>
      </div>
    )
  }
)

export default ScrollContainer
