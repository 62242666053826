import { HomeOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'

import HomePage from 'hsu/pages/Home/index'

const home: RouteItem = {
  key: 'home',
  name: '首页',
  path: '/',
  lazy: false,
  layout: 'BasicLayout',
  icon: <HomeOutlined />,
  component: HomePage
}

export default home
