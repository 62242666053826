/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const PURCHASE_CONTRACT_SEND_GOODS_BILL_BTN =
  'purchaseContractGenerateDeliverGoodsBtn'

export const PURCHASE_CONTRACT_SEND_GOODS_BILL_OPTION: OptionItem = {
  key: PURCHASE_CONTRACT_SEND_GOODS_BILL_BTN,
  name: '生成送货单'
}
