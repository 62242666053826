import { RouteItem } from 'common/router/interface'

const exception: RouteItem = {
  key: 'exception',
  name: '异常页面',
  path: '/exception',
  redirect: '/exception/404',
  authorize: false,
  hideInMenu: true,
  children: [
    {
      key: 'exception403',
      name: '403 Forbidden',
      path: '/exception/403',
      component: () => import('hsu/pages/Exception/403')
    },
    {
      key: 'exception500',
      name: '500 Server Error',
      path: '/exception/500',
      component: () => import('hsu/pages/Exception/500')
    },
    {
      key: 'exception404',
      name: '404 Not Found',
      path: '/exception/404',
      component: () => import('hsu/pages/Exception/404')
    }
  ]
}

export default exception
