/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const ACCEPTANCE_ADJUST_BTN = 'acceptanceAdjustBtn'

export const ACCEPTANCE_ADJUST_OPTION: OptionItem = {
  key: ACCEPTANCE_ADJUST_BTN,
  name: '调整'
}
