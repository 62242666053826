import { isNil, round } from 'lodash-es'

export const isNumber = (value: any): value is number => {
  return !(isNil(value) || isNaN(Number(value)))
}

/**
 * 科学计数法转换成正常的数字
 */
export const exponential2Number = (value: number | string) => {
  return +value
}

/**
 *
 * 精度预处理（解决数字在计算中出现的.999999999 或.000000001）
 */
export const precisionPreHandle = (value: number) => {
  return round(value, 10)
}

/**
 * 统一项目中的精度
 */
export const precisionUtil = {
  format: (value?: number | string, precision?: number) => {
    if (isNumber(value)) {
      return round(precisionPreHandle(value), precision)
    }

    console.warn(
      `precisionUtil.format 接受到不是 number 类型的参数 ${value}，返回值被强制为 0`
    )

    return 0
  },
  /**
   *  默认保留 12 位小数
   */
  default: (value?: number | string) => {
    return precisionUtil.format(value, 12)
  },
  /**
   * 金额保留 2 位小数
   */
  amount: (value?: number | string) => {
    return precisionUtil.format(value, 2)
  },
  /**
   * 数量保留 6 位小数
   */
  quantity: (value?: number | string) => {
    return precisionUtil.format(value, 6)
  },
  /**
   * 价格保留 8 位小数
   */
  price: (value?: number | string) => {
    return precisionUtil.format(value, 8)
  },
  /**
   * 物资件数 保留 2 位小数
   */
  goodsNum: (value?: number | string) => {
    return precisionUtil.format(value, 2)
  }
}

export const formatUtil = {
  style: (options: {
    value?: string | number | null
    localeMatcher?: string
    style?: string
    currency?: string
    currencyDisplay?: string
    currencySign?: string
    useGrouping?: boolean
    minimumIntegerDigits?: number
    minimumFractionDigits?: number
    maximumFractionDigits?: number
    minimumSignificantDigits?: number
    maximumSignificantDigits?: number
  }) => {
    const { value, minimumFractionDigits, ...restOptions } = options

    if (isNil(value) || isNaN(Number(value))) {
      return value
    }

    const precision = minimumFractionDigits ?? `${value}`.split('.')[1]?.length

    return new Intl.NumberFormat('zh-Hans-CN', {
      ...restOptions,
      minimumFractionDigits: precision
    }).format(Number(value))
  },
  /**
   * 使用逗号分割
   */
  decimal: (value?: string | number | null, precision?: number) => {
    return formatUtil.style({
      value,
      style: 'decimal',
      useGrouping: true,
      minimumFractionDigits: precision
    })
  },
  /**
   * 展示人民币符号
   */
  currency: (value?: string | number, precision?: number) => {
    return formatUtil.style({
      value,
      currency: 'CNY',
      style: 'currency',
      minimumFractionDigits: precision
    })
  }
}
