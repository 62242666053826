/**
 * @desc 根据id查询
 */
import { ContractEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type ProcurementContractGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type ProcurementContractGetByIdResponse = ContractEntity

export const procurementContractGetByIdMethod = 'GET'

export const procurementContractGetByIdUrl = '/hsu/v1/contract/byId/{id}'

export function procurementContractGetByIdRequest(
  params?: ProcurementContractGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<ProcurementContractGetByIdResponse>({
    url: procurementContractGetByIdUrl,
    method: procurementContractGetByIdMethod,
    params,
    ...options
  })
}

procurementContractGetByIdRequest.method = procurementContractGetByIdMethod
procurementContractGetByIdRequest.url = procurementContractGetByIdUrl
