import { BrowserRouter } from 'react-router-dom'

import { CurrentUserProvider } from 'common/context/CurrentUser'
import { checkLogin } from 'common/lib/util'
import Router from 'common/router'

import { formilySetup } from './@formily/setup'
import { BASE_URL } from './lib/constant'
import { RouterProps as AppProps } from './router/interface'

import 'moment/dist/locale/zh-cn'

checkLogin()

formilySetup()

const App = (props: AppProps) => {
  const { routes } = props

  return (
    <BrowserRouter basename={BASE_URL}>
      <CurrentUserProvider>
        <Router routes={routes} />
      </CurrentUserProvider>
    </BrowserRouter>
  )
}

export default App
