/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const OFFER_BTN = 'offerBtn'

export const OFFER_AGAIN_BTN = 'offerAgainBtn'

export const OFFER_OPTION: OptionItem = {
  key: OFFER_BTN,
  name: '报价'
}

export const OFFER__AGAIN_OPTION: OptionItem = {
  key: OFFER_AGAIN_BTN,
  name: '重新报价'
}
