import { Spin } from 'antd'

import { PageLoadingProps } from './interface'

const PageLoading = (props: PageLoadingProps) => {
  const { tip = '加载中...' } = props
  return (
    <div style={{ paddingTop: 100, textAlign: 'center' }}>
      <Spin size='large' tip={tip} />
    </div>
  )
}

export default PageLoading
