import LayoutHeaderAvator from './Avator'

import './RightContent.less'

const LayoutHeaderRightContent = () => {
  return (
    <div className='layout-header-right-content'>
      <LayoutHeaderAvator />
    </div>
  )
}

export default LayoutHeaderRightContent
