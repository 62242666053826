/**
 * 物资类别页面上的操作按钮
 */

import { OptionItem } from 'common/router/interface'

export const GOODS_TYPE_CREATE_BTN = 'goodsTypeCreateBtn'

export const GOODS_TYPE_UPDATE_BTN = 'goodsTypeUpdateBtn'

export const GOODS_TYPE_DELETE_BTN = 'goodsTypeDeleteBtn'

export const GOODS_TYPE_CREATE_OPTION: OptionItem = {
  key: GOODS_TYPE_CREATE_BTN,
  name: '添加类别'
}

export const GOODS_TYPE_UPDATE_OPTION: OptionItem = {
  key: GOODS_TYPE_UPDATE_BTN,
  name: '编辑类别'
}

export const GOODS_TYPE_DELETE_OPTION: OptionItem = {
  key: GOODS_TYPE_DELETE_BTN,
  name: '删除类别'
}
