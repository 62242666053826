import { useRef } from 'react'

const useCreation = <T>(factory: () => T, deps?: any[]) => {
  const { current } = useRef({
    deps,
    obj: undefined as undefined | T,
    initialized: false
  })
  if (current.initialized === false || !depsAreSame(current.deps, deps)) {
    current.deps = deps
    current.obj = factory()
    current.initialized = true
  }
  return current.obj as T
}

function depsAreSame(oldDeps?: any[], deps?: any[]): boolean {
  if (oldDeps === deps) return true
  if (oldDeps === undefined || deps === undefined) {
    return false
  }
  for (let i = 0; i < oldDeps.length; i++) {
    if (oldDeps[i] !== deps[i]) {
      return false
    }
  }
  return true
}

export default useCreation
