import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, message, Tooltip } from 'antd'

import { MessageEntity } from 'api/hsu/interface'

import useRequest from 'common/hooks/useRequest'

import { ConfigMap } from '../util'

type LinkButtonProps = {
  item: MessageEntity
}

const LinkButton = (props: LinkButtonProps) => {
  const { item } = props
  const config = ConfigMap[item.busType as string]
  const api = config?.api

  const history = useHistory()

  const { data, run, loading } = useRequest({
    api,
    manual: true,
    requestParams: { id: item.busId },
    onError: error => message.error(error.message)
  })

  useEffect(() => {
    if (data) {
      const subPath = data.status === -1 ? '/edit' : '/detail'
      const finalPath = config?.basePath + subPath + '?id=' + data.id
      history.push(finalPath)
    }
  }, [data])

  if (!config) {
    return (
      <Tooltip title='不支持的代办类型，请联系管理员'>
        <Button disabled type='text'>
          详情
        </Button>
      </Tooltip>
    )
  }

  return (
    <Button loading={loading} type='text' onClick={() => run()}>
      详情
    </Button>
  )
}

export default LinkButton
