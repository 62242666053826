import { Noop } from 'common/interface/base'

const isEqualArr = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (const item of arr1) {
    if (!arr2.includes(item)) {
      return false
    }
  }

  return true
}

const isObj = (obj: any) => typeof obj === 'object' && obj !== null

export const isEqual = (
  obj1: Record<any, any> = {},
  obj2: Record<any, any> = {}
) => {
  if (obj1 === obj2) {
    return true
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (!isEqualArr(keys1, keys2)) {
    return false
  }

  for (const key of keys1) {
    const val1 = obj1[key]
    const val2 = obj2[key]

    if (Object.is(val1, val2)) {
      continue
    }

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!isEqualArr(val1, val2)) {
        return false
      }
    }

    if (isObj(val1) && isObj(val2)) {
      if (!isEqual(val1, val2)) {
        return false
      }
    }

    return false
  }

  return true
}

export const createDelay = () => {
  const timestamp = Date.now()

  return (cb: Noop, wait: number) => {
    const time = Date.now() - timestamp

    const restTime = wait - time

    if (restTime <= 0) {
      cb()
    } else {
      setTimeout(cb, restTime)
    }
  }
}
