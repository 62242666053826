/**
 * @desc 根据id查询
 */
import { PuBargainEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type PuBargainGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type PuBargainGetByIdResponse = PuBargainEntity

export const puBargainGetByIdMethod = 'GET'

export const puBargainGetByIdUrl = '/hsu/v1/puBargain/byId/{id}'

export function puBargainGetByIdRequest(
  params?: PuBargainGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<PuBargainGetByIdResponse>({
    url: puBargainGetByIdUrl,
    method: puBargainGetByIdMethod,
    params,
    ...options
  })
}

puBargainGetByIdRequest.method = puBargainGetByIdMethod
puBargainGetByIdRequest.url = puBargainGetByIdUrl
