import { useEffect, useState } from 'react'

import { Button, Card, Divider } from 'antd'

import { MessageEntity } from 'api/hsu/interface'
import { messagePageRequest } from 'api/hsu/message/page'

import { useUserConfig } from 'common/context/UserConfig'
import useDebounceFn from 'common/hooks/useDebounceFn'
import useRequest from 'common/hooks/useRequest'

import TodoBadge from './Badge'
import TodoList from './List'
import TodoSetting from './Setting'

const api = messagePageRequest

const Todo = () => {
  const [current, setCurrent] = useState(1)
  const [listData, setListData] = useState<MessageEntity[]>([])

  const { refresh: refreshFlag, refreshInterval = 5 } = useUserConfig()
  const refreshIntervalMs = refreshInterval * 60 * 1000

  const {
    data,
    loading,
    refresh: refreshAction
  } = useRequest({
    api,
    manual: false,
    defaultParams: {
      size: 10,
      orders: [
        {
          asc: false,
          column: 'time'
        }
      ]
    },
    requestParams: { current }
  })

  const total = data?.total || 0

  const refresh = () => {
    if (current === 1) {
      refreshAction()
    } else {
      setCurrent(1)
    }
  }

  const loadMore = useDebounceFn(
    () => {
      setCurrent(current + 1)
    },
    { wait: 200 }
  )

  useEffect(() => {
    const records = data?.records || []
    const oldData = listData || []

    setListData(current === 1 ? records : oldData.concat(records))
  }, [data])

  /**
   * 自动刷新
   */
  useEffect(() => {
    let timer: number
    if (refreshFlag) {
      timer = window.setTimeout(refresh, refreshIntervalMs)
    }

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <Card
      bordered
      bodyStyle={{ padding: 0 }}
      extra={
        <div>
          <Button
            key='refresh'
            disabled={loading}
            type='link'
            onClick={refresh}
          >
            刷 新
          </Button>
          <Divider type='vertical' />
          <TodoSetting />
        </div>
      }
      headStyle={{ paddingRight: '8px' }}
      title={<TodoBadge total={total} />}
    >
      <TodoList
        data={listData}
        loading={loading}
        loadMore={loadMore}
        total={total}
      />
    </Card>
  )
}

export default Todo
