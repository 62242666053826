import { connect, mapProps } from '@formily/react'
import { Switch as AntdSwitch } from 'antd'

export const Switch = connect(
  AntdSwitch,
  mapProps(
    {
      value: 'checked'
    },
    props => {
      const { onChange, ...restProps } = props
      return {
        ...restProps,
        onChange(...args) {
          onChange?.(...args)
        }
      }
    }
  )
)

export default Switch
