import { ContainerOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  DELETE_OPTION,
  INVALID_OPTION,
  READ_OPTION,
  UPDATE_OPTION
} from 'common/router/option'

import { PURCHASE_CONTRACT_SEND_GOODS_BILL_OPTION } from 'hsu/pages/Contract/Purchase/option'
import { SALE_CONTRACT_AUDIT_RECEIPT_OPTION } from 'hsu/pages/Contract/Sale/option'

const contract: RouteItem = {
  key: 'contract',
  name: '合同管理',
  path: '/contract',
  layout: 'PageLayout',
  redirect: '/contract/purchase',
  icon: <ContainerOutlined />,
  children: [
    {
      key: 'purchaseContract',
      name: '采购合同',
      path: '/contract/purchase',
      redirect: '/contract/purchase/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'purchaseContractList',
          name: '合同列表',
          path: '/contract/purchase/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            INVALID_OPTION,
            PURCHASE_CONTRACT_SEND_GOODS_BILL_OPTION
          ],
          component: () => import('hsu/pages/Contract/Purchase/List')
        },
        {
          key: 'purchaseContractCreate',
          name: '新增合同',
          path: '/contract/purchase/create',
          component: () => import('hsu/pages/Contract/Purchase/Create')
        },
        {
          key: 'purchaseContractEdit',
          name: '编辑合同',
          path: '/contract/purchase/edit',
          component: () => import('hsu/pages/Contract/Purchase/Edit')
        },
        {
          key: 'purchaseContractDetail',
          name: '合同详情',
          path: '/contract/purchase/detail',
          component: () => import('hsu/pages/Contract/Purchase/Detail')
        }
      ]
    },
    {
      key: 'saleContract',
      name: '销售合同',
      path: '/contract/sale',
      redirect: '/contract/sale/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'saleContractList',
          name: '合同列表',
          path: '/contract/sale/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            INVALID_OPTION,
            SALE_CONTRACT_AUDIT_RECEIPT_OPTION
          ],
          component: () => import('hsu/pages/Contract/Sale/List')
        },
        {
          key: 'saleContractCreate',
          name: '新增合同',
          path: '/contract/sale/create',
          component: () => import('hsu/pages/Contract/Sale/Create')
        },
        {
          key: 'saleContractEdit',
          name: '编辑合同',
          path: '/contract/sale/edit',
          component: () => import('hsu/pages/Contract/Sale/Edit')
        },
        {
          key: 'saleContractDetail',
          name: '合同详情',
          path: '/contract/sale/detail',
          component: () => import('hsu/pages/Contract/Sale/Detail')
        }
      ]
    },
    {
      key: 'transportContract',
      name: '运输合同',
      path: '/contract/transport',
      redirect: '/contract/transport/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'transportContractList',
          name: '运输合同列表',
          path: '/contract/transport/list',
          props: {
            busiType: 1
          },
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Contract/Approve/Bill/List')
        },
        {
          key: 'transportContractCreate',
          name: '新增运输合同',
          path: '/contract/transport/create',
          props: {
            busiType: 1
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Create')
        },
        {
          key: 'transportContractEdit',
          name: '编辑运输合同',
          path: '/contract/transport/edit',
          props: {
            busiType: 1
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Edit')
        },
        {
          key: 'transportContractDetail',
          name: '运输合同详情',
          path: '/contract/transport/detail',
          props: {
            busiType: 1
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Detail')
        }
      ]
    },
    {
      key: 'serveContract',
      name: '服务合同',
      path: '/contract/serve',
      redirect: '/contract/serve/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'serveContractList',
          name: '服务合同列表',
          path: '/contract/serve/list',
          props: {
            busiType: 2
          },
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Contract/Approve/Bill/List')
        },
        {
          key: 'serveContractCreate',
          name: '新增服务合同',
          path: '/contract/serve/create',
          props: {
            busiType: 2
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Create')
        },
        {
          key: 'serveContractEdit',
          name: '编辑服务合同',
          path: '/contract/serve/edit',
          props: {
            busiType: 2
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Edit')
        },
        {
          key: 'serveContractDetail',
          name: '服务合同详情',
          path: '/contract/serve/detail',
          props: {
            busiType: 2
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Detail')
        }
      ]
    },
    {
      key: 'otherContract',
      name: '其他合同',
      path: '/contract/other',
      redirect: '/contract/other/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'otherContractList',
          name: '其他合同列表',
          path: '/contract/other/list',
          props: {
            busiType: 0
          },
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Contract/Approve/Bill/List')
        },
        {
          key: 'otherContractCreate',
          name: '新增其他合同',
          path: '/contract/other/create',
          props: {
            busiType: 0
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Create')
        },
        {
          key: 'otherContractEdit',
          name: '编辑其他合同',
          path: '/contract/other/edit',
          props: {
            busiType: 0
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Edit')
        },
        {
          key: 'otherContractDetail',
          name: '其他合同详情',
          path: '/contract/other/detail',
          props: {
            busiType: 0
          },
          component: () => import('hsu/pages/Contract/Approve/Bill/Detail')
        }
      ]
    },
    {
      key: 'otherApprove',
      name: '用章申请',
      path: '/approve/other',
      redirect: '/approve/other/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'otherApproveList',
          name: '用章申请列表',
          path: '/approve/other/list',
          props: {
            busiType: 3
          },
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Contract/Approve/Other/List')
        },
        {
          key: 'otherApproveCreate',
          name: '新增用章申请',
          path: '/approve/other/create',
          props: {
            busiType: 3
          },
          component: () => import('hsu/pages/Contract/Approve/Other/Create')
        },
        {
          key: 'otherApproveEdit',
          name: '编辑用章申请',
          path: '/approve/other/edit',
          props: {
            busiType: 3
          },
          component: () => import('hsu/pages/Contract/Approve/Other/Edit')
        },
        {
          key: 'otherApproveDetail',
          name: '用章申请详情',
          path: '/approve/other/detail',
          props: {
            busiType: 3
          },
          component: () => import('hsu/pages/Contract/Approve/Other/Detail')
        }
      ]
    },
    {
      key: 'contractTemplate',
      name: '合同模板',
      path: '/contract/template',
      redirect: '/contract/template/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'contractTemplateList',
          name: '合同模板列表',
          path: '/contract/template/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Contract/Template/List')
        },
        {
          key: 'contractTemplateCreate',
          name: '新增合同模板',
          path: '/contract/template/create',
          component: () => import('hsu/pages/Contract/Template/Create')
        },
        {
          key: 'contractTemplateEdit',
          name: '编辑合同模板',
          path: '/contract/template/edit',
          component: () => import('hsu/pages/Contract/Template/Edit')
        },
        {
          key: 'contractTemplateDetail',
          name: '合同模板详情',
          path: '/contract/template/detail',
          component: () => import('hsu/pages/Contract/Template/Detail')
        }
      ]
    }
  ]
}

export default contract
