/**
 * @desc 根据id查询
 */
import { AppraisalBillEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type AppraisalBillGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type AppraisalBillGetByIdResponse = AppraisalBillEntity

export const appraisalBillGetByIdMethod = 'GET'

export const appraisalBillGetByIdUrl = '/hsu/v1/appraisalBill/byId/{id}'

export function appraisalBillGetByIdRequest(
  params?: AppraisalBillGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<AppraisalBillGetByIdResponse>({
    url: appraisalBillGetByIdUrl,
    method: appraisalBillGetByIdMethod,
    params,
    ...options
  })
}

appraisalBillGetByIdRequest.method = appraisalBillGetByIdMethod
appraisalBillGetByIdRequest.url = appraisalBillGetByIdUrl
