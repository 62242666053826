import { useRef } from 'react'

import { Noop } from 'common/interface/base'

function usePersistFn<T extends Noop>(fn: T) {
  const fnRef = useRef<T>(fn)
  fnRef.current = fn

  const persistFn = useRef<T>()

  if (!persistFn.current) {
    persistFn.current = function (...args) {
      return fnRef.current.apply(undefined, args)
    } as T
  }

  Object.assign(persistFn.current, fn)

  return persistFn.current as T
}

export default usePersistFn
