/**
 * @desc 根据id查询
 */
import { PuPriceCompareEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type PuPriceCompareGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type PuPriceCompareGetByIdResponse = PuPriceCompareEntity

export const puPriceCompareGetByIdMethod = 'GET'

export const puPriceCompareGetByIdUrl = '/hsu/v1/puPriceCompare/byId/{id}'

export function puPriceCompareGetByIdRequest(
  params?: PuPriceCompareGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<PuPriceCompareGetByIdResponse>({
    url: puPriceCompareGetByIdUrl,
    method: puPriceCompareGetByIdMethod,
    params,
    ...options
  })
}

puPriceCompareGetByIdRequest.method = puPriceCompareGetByIdMethod
puPriceCompareGetByIdRequest.url = puPriceCompareGetByIdUrl
