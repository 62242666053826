/**
 * @desc 根据id查询
 */
import { EstimatedReceiptBillEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type EstimatedReceiptBillGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type EstimatedReceiptBillGetByIdResponse = EstimatedReceiptBillEntity

export const estimatedReceiptBillGetByIdMethod = 'GET'

export const estimatedReceiptBillGetByIdUrl =
  '/hsu/v1/estimatedReceiptBill/byId/{id}'

export function estimatedReceiptBillGetByIdRequest(
  params?: EstimatedReceiptBillGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<EstimatedReceiptBillGetByIdResponse>({
    url: estimatedReceiptBillGetByIdUrl,
    method: estimatedReceiptBillGetByIdMethod,
    params,
    ...options
  })
}

estimatedReceiptBillGetByIdRequest.method = estimatedReceiptBillGetByIdMethod
estimatedReceiptBillGetByIdRequest.url = estimatedReceiptBillGetByIdUrl
