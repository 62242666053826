import { Dropdown, DropDownProps } from 'antd'
import classNames from 'classnames'

import './Dropdown.less'

export interface LayoutHeaderDropdownProps extends DropDownProps {
  overlayClassName?: string
  placement?:
    | 'bottomLeft'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomCenter'
}

const LayoutHeaderDropdown: React.FC<LayoutHeaderDropdownProps> = ({
  overlayClassName: cls,
  ...restProps
}) => (
  <Dropdown
    overlayClassName={classNames('layout-header-dropdown', cls)}
    {...restProps}
  />
)

export default LayoutHeaderDropdown
