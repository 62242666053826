/**
 * @desc 分页
 */
import { PageMessageEntity, OrderItem } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type MessagePageParams = Partial<{
  /** size */
  size: number
  /** current */
  current: number
  /** orders */
  orders?: Array<OrderItem>
}>

export type MessagePageResponse = PageMessageEntity

export const messagePageMethod = 'GET'

export const messagePageUrl = '/hsu/v1/message'

export function messagePageRequest(
  params?: MessagePageParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<MessagePageResponse>({
    url: messagePageUrl,
    method: messagePageMethod,
    params,
    ...options
  })
}

messagePageRequest.method = messagePageMethod
messagePageRequest.url = messagePageUrl
