import { ForwardedRef, forwardRef } from 'react'

import { AutoCompleteFormProps } from './interface'

const AutoCompleteForm = forwardRef(
  (props: AutoCompleteFormProps, ref: ForwardedRef<HTMLFormElement>) => {
    const { onSubmit, ...restProps } = props

    return (
      <form
        ref={ref}
        autoComplete='on'
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          onSubmit?.(e)
        }}
        {...restProps}
      />
    )
  }
)

export default AutoCompleteForm
