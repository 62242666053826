import { ModalProps } from 'antd'

/**
 * Modal 弹窗的通用配置信息
 */
const modalConfig: ModalProps = {
  centered: false,
  closable: true,
  mask: true,
  maskClosable: false,
  keyboard: true
}

export default modalConfig
