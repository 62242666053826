/**
 * @desc 根据id查询
 */
import { BmBargainResponse } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type BmBargainGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type BmBargainGetByIdResponse = BmBargainResponse

export const bmBargainGetByIdMethod = 'GET'

export const bmBargainGetByIdUrl = '/hsu/v1/bargain/byId/{id}'

export function bmBargainGetByIdRequest(
  params?: BmBargainGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<BmBargainGetByIdResponse>({
    url: bmBargainGetByIdUrl,
    method: bmBargainGetByIdMethod,
    params,
    ...options
  })
}

bmBargainGetByIdRequest.method = bmBargainGetByIdMethod
bmBargainGetByIdRequest.url = bmBargainGetByIdUrl
