import ReactDOM from 'react-dom'

import { ConfigProvider as AntdConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'

import { customerCollectDelByBusinessRequest } from 'api/hsu/customerCollect/delByBusiness'
import { customerCollectGetByBusinessRequest } from 'api/hsu/customerCollect/getByBusiness'
import { customerCollectInsertDataRequest } from 'api/hsu/customerCollect/insertData'
import { workFlowCheckApproveRequest } from 'api/hsu/workFlow/checkApprove'
import { workFlowListRecordRequest } from 'api/hsu/workFlow/listRecord'

import {
  ProjectConfigContextState,
  ProjectConfigProvider
} from 'common/context/ProjectConfig'
import Main from 'common/Main'

import routes from './routes'

const ProjectConfig: ProjectConfigContextState = {
  showHeader: true,
  theme: 'light',
  apis: {
    collect: {
      create: customerCollectInsertDataRequest,
      read: customerCollectGetByBusinessRequest,
      delete: customerCollectDelByBusinessRequest
    },
    workflow: {
      checkApprovePermission: workFlowCheckApproveRequest,
      getApproveRecords: workFlowListRecordRequest
    }
  }
}

ReactDOM.render(
  <ProjectConfigProvider value={ProjectConfig}>
    <AntdConfigProvider
      componentSize='small'
      locale={zhCN}
      space={{ size: 'middle' }}
    >
      <Main routes={routes} />
    </AntdConfigProvider>
  </ProjectConfigProvider>,
  document.getElementById('root')
)
