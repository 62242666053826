/**
 * @desc 根据id查询
 */
import { SaleBillEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type SaleBillGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type SaleBillGetByIdResponse = SaleBillEntity

export const saleBillGetByIdMethod = 'GET'

export const saleBillGetByIdUrl = '/hsu/v1/saleBill/byId/{id}'

export function saleBillGetByIdRequest(
  params?: SaleBillGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<SaleBillGetByIdResponse>({
    url: saleBillGetByIdUrl,
    method: saleBillGetByIdMethod,
    params,
    ...options
  })
}

saleBillGetByIdRequest.method = saleBillGetByIdMethod
saleBillGetByIdRequest.url = saleBillGetByIdUrl
