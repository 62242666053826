import { FC } from 'react'

import classNames from 'classnames'

import { useProjectConfig } from 'common/context/ProjectConfig'
import { useSize } from 'common/hooks/useSize'

import LayoutSkeleton from '../components/LayoutSkeleton'
import PageContainer from '../components/PageContainer'
import PageHeader from '../components/PageHeader'

import './index.less'

/**
 * 带 PageHeader 的布局
 */
const PageLayout: FC = ({ children }) => {
  const size = useSize()
  const { showHeader } = useProjectConfig()

  const rootPrefixCls = 'page-layout'

  const wrapperPrefix = `${rootPrefixCls}-wrapper`
  const wrapperClassName = classNames(wrapperPrefix, {
    [`${wrapperPrefix}-sm`]: size === 'small'
  })

  return (
    <LayoutSkeleton className={rootPrefixCls}>
      {showHeader && <PageHeader />}
      <div className={wrapperClassName}>
        <PageContainer>{children}</PageContainer>
      </div>
    </LayoutSkeleton>
  )
}

export default PageLayout
