import { createContext, useContext } from 'react'

import { UserConfigEntity } from 'api/auth/interface'
import { userConfigQueryCurrentUserConfigRequest } from 'api/auth/userConfig/queryCurrentUserConfig'
import { userConfigUpdateCurrentUserConfigRequest } from 'api/auth/userConfig/updateCurrentUserConfig'

import { wrapApi } from 'common/lib/util'

import useRequest from '../hooks/useRequest'
import useSafeAction from '../hooks/useSafeAction'

type UserConfigContextState = UserConfigEntity & {
  dispatch?: (value: UserConfigEntity) => void
}

const DEFAULT_REFRESH_INTERVAL = 300

const getApi = wrapApi(userConfigQueryCurrentUserConfigRequest, {
  silent: true,
  cacheable: true
})

const updateApi = userConfigUpdateCurrentUserConfigRequest

export const UserConfigContext = createContext<UserConfigContextState>({
  refreshInterval: DEFAULT_REFRESH_INTERVAL
})

export const useUserConfig = () => useContext(UserConfigContext)

export const UserConfigProvider: React.FC = props => {
  const { data = {}, mutate } = useRequest({
    api: getApi,
    manual: false
  })

  const safeDispatch = useSafeAction(
    (s: UserConfigEntity | ((s: UserConfigEntity) => UserConfigEntity)) => {
      const state = typeof s === 'function' ? s(data) : s

      mutate({
        ...data,
        ...state
      })
      updateApi({
        ...state,
        id: data.id
      })
    }
  )

  return (
    <UserConfigContext.Provider
      {...props}
      value={{
        refreshInterval: DEFAULT_REFRESH_INTERVAL,
        ...data,
        dispatch: safeDispatch
      }}
    />
  )
}
