import { TeamOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  DELETE_OPTION,
  UPDATE_OPTION,
  READ_OPTION,
  AUDIT_OPTION,
  INVALID_OPTION
} from 'common/router/option'

const customer: RouteItem = {
  key: 'customer',
  name: '企业管理',
  path: '/customer',
  redirect: '/customer/profile',
  layout: 'PageLayout',
  icon: <TeamOutlined />,
  children: [
    {
      key: 'customerProfile',
      name: '企业档案',
      path: '/customer/profile',
      redirect: '/customer/profile/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'customerProfileList',
          name: '企业档案',
          path: '/customer/profile/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Customer/Profile/List')
        },
        {
          key: 'customerProfileCreate',
          name: '新增企业',
          path: '/customer/profile/create',
          component: () => import('hsu/pages/Customer/Profile/Create')
        },
        {
          key: 'customerProfileEdit',
          name: '编辑企业',
          path: '/customer/profile/edit',
          component: () => import('hsu/pages/Customer/Profile/Edit')
        },
        {
          key: 'customerProfileDetail',
          name: '企业详情',
          path: '/customer/profile/detail',
          component: () => import('hsu/pages/Customer/Profile/Detail')
        }
      ]
    },
    {
      key: 'customerCredit',
      name: '企业授信',
      path: '/customer/credit',
      redirect: '/customer/credit/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'customerCreditList',
          name: '企业授信',
          path: '/customer/credit/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Customer/Credit/List')
        },
        {
          key: 'customerCreditCreate',
          name: '添加申请',
          path: '/customer/credit/create',
          component: () => import('hsu/pages/Customer/Credit/Create')
        },
        {
          key: 'customerCreditEdit',
          name: '编辑申请',
          path: '/customer/credit/edit',
          component: () => import('hsu/pages/Customer/Credit/Edit')
        },
        {
          key: 'customerCreditDetail',
          name: '授信详情',
          path: '/customer/credit/detail',
          component: () => import('hsu/pages/Customer/Credit/Detail')
        }
      ]
    },
    {
      key: 'customerSupplier',
      name: '物资准入',
      path: '/customer/supplier',
      redirect: '/customer/supplier/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'customerSupplierList',
          name: '物资准入列表',
          path: '/customer/supplier/list',
          options: [
            CREATE_OPTION,
            UPDATE_OPTION,
            DELETE_OPTION,
            READ_OPTION,
            AUDIT_OPTION,
            INVALID_OPTION
          ],
          component: () => import('hsu/pages/Customer/SupplierGoods/List')
        },
        {
          key: 'customerSupplierCreate',
          name: '新增物资准入',
          path: '/customer/supplier/create',
          component: () => import('hsu/pages/Customer/SupplierGoods/Create')
        },
        {
          key: 'customerSupplierEdit',
          name: '编辑物资准入',
          path: '/customer/supplier/edit',
          component: () => import('hsu/pages/Customer/SupplierGoods/Edit')
        },
        {
          key: 'customerSupplierDetail',
          name: '物资准入详情',
          path: '/customer/supplier/detail',
          component: () => import('hsu/pages/Customer/SupplierGoods/Detail')
        }
      ]
    }
  ]
}

export default customer
