import { Link } from 'react-router-dom'

import classNames from 'classnames'

import { AdvanceLinkProps } from './interface'

import './index.less'

/**
 * 预先包装一下，方便以后适配 tag 模式的路由
 *
 * @param props
 */
const AdvanceLink = (props: AdvanceLinkProps) => {
  const { className, to = '', disabled, ...restProps } = props

  const rootClass = classNames('advance-link', className)

  return disabled ? (
    <span {...restProps} className={rootClass} />
  ) : (
    <Link {...restProps} className={rootClass} to={to} />
  )
}

export default AdvanceLink
