/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const ENQUIRY_VIEW_COMPARE_BTN = 'enquiryViewCompareBtn'

export const ENQUIRY_VIEW_COMPARE_OPTION: OptionItem = {
  key: ENQUIRY_VIEW_COMPARE_BTN,
  name: '查看比价单'
}
