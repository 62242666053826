import { useMemo } from 'react'

import { ISchema } from '@formily/json-schema'

import FormBlock from 'common/@formily/components/FormBlock'

import { CrudFormBlock, CrudSchemaItem } from '../interface'
import { createRandomNameCreator } from '../lib/util'

type UseTransformProps = {
  grid?: boolean
  items?: CrudSchemaItem[]
}

const resolveFormBlock = (item: CrudFormBlock, grid?: boolean) => {
  const {
    itemType,
    name,
    children,
    'x-display': x_display,
    'x-hidden': x_hidden,
    'x-visible': x_visible,
    'x-reactions': x_reactions,
    ...restPrpos
  } = item

  const formBlockSchema: ISchema = {
    name,
    type: 'void',
    'x-display': x_display,
    'x-hidden': x_hidden,
    'x-visible': x_visible,
    'x-component': FormBlock,
    'x-component-props': {
      ...restPrpos
    },
    'x-reactions': x_reactions,
    properties: resolveProperties({
      items: children,
      grid: !!(grid || item.grid)
    })
  }

  return formBlockSchema
}

const resolveProperties = ({ grid, items }: UseTransformProps) => {
  if (!items) {
    return undefined
  }

  const getRandomName = createRandomNameCreator()

  const properties: Record<string, ISchema> = {}

  for (const item of items) {
    if (item.itemType === 'FormBlock') {
      const voidSchema = resolveFormBlock(item)
      const name = voidSchema.name || getRandomName()
      properties[name] = voidSchema
    } else if (item.name) {
      const { name, ...fieldProps } = item
      properties[name] = fieldProps
    }
  }

  return properties
}

const resolveSchema = (props: UseTransformProps) => {
  const schema: ISchema = {
    type: 'object',
    properties: resolveProperties(props)
  }

  return schema
}

/**
 * 把自定义的 schema 转成符合 formily 标准的 schema
 *
 *  1. array to object
 *  2. children to properties
 * @param items 表单配置项
 * @returns formily schema
 */
const useTransformSchema = ({ grid, items }: UseTransformProps) => {
  return useMemo(() => resolveSchema({ grid, items }), [grid, items])
}

export default useTransformSchema
