/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const BARGAIN_OFFER_BTN = 'bargainOfferBtn'

export const BARGAIN_OFFER_AGAIN_BTN = 'bargainAgainOfferBtn'

export const BARGAIN_OFFER_OPTION: OptionItem = {
  key: BARGAIN_OFFER_BTN,
  name: '报价'
}

export const BARGAIN_OFFER_AGAIN_OPTION: OptionItem = {
  key: BARGAIN_OFFER_AGAIN_BTN,
  name: '确认报价'
}
